.chat-container {
    display: flex;
    height: 80vh;
    overflow: hidden;
}

.contact-list {
    flex: none;
    width: 27%;
    background-color: #f8f8f8;
    border-right: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    padding: 20px;
    overflow-y: auto;
    padding-top: 0;
}

.contact-list h3 {
    margin-bottom: 20px;
    padding-top: 15px;
}

.contact-list h3 {
    font-size: 20px;
}

.contact-item {
    padding: 3px 10px;
    cursor: pointer;
    border-radius: 17px;
    transition: background-color 0.2s;
    border: 1px solid rgb(220, 212, 212);
    margin-bottom: 5px;
}

.contact-item:hover {
    background-color: #eaeaea;
}

.contact-item.active {
    background-color: #ddd;
    font-weight: bold;
    color: #4440da;
}

.chat-section {
    flex: 2;
    display: flex;
    flex-direction: column;
}

.chat-section-2 {
    flex: 2;
    display: none;
    flex-direction: column;
}

.message-area {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 20px;
    overflow-y: auto;
    padding-bottom: 0;
}

.messages {
    flex-grow: 1;
    overflow-y: scroll;
    height: 315px;
}

.message {
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    max-width: 60%;
}

.from-me {
    background-color: #daf8cb;
    align-self: flex-end;
}

.from-them {
    background-color: #ebebeb;
    align-self: flex-start;
}

.message-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-top: 1px solid #ddd;
}

.input-container {
    position: relative;
    flex-grow: 1;
}

.input-icons .icon {
    font-size: 17px;
    cursor: pointer;
}

.input-container input {
    width: 100%;
    padding: 10px 50px 10px 15px;
    border: 1px solid #ddd;
    border-radius: 25px;
    font-size: 13px;
}

.input-icons {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    gap: 10px;
}

.input-icons i {
    margin-left: 10px;
    font-size: 20px;
    cursor: pointer;
}

.send-button {
    background: none;
    border: none;
    font-size: 24px;
    margin-left: 10px;
    cursor: pointer;
    color: #007bff;
}


.no-chat {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.no-chat h3 {
    font-size: 17px;
}

.contact {
    display: flex;
}

.contact-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.contact-img img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.chat-user {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.chat-topbar {
    padding-left: 20px;
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    position: sticky;
    top: 0;
    background: white;
    border-bottom: 1px solid #e5d4d4;
    gap: 17px;
}

.chat-user img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.chat-user-name p {
    font-size: 11px;
}

.contact-name {
    margin-left: 20px;
}

.contact-name p {
    font-size: 13px;
    margin-top: 6px;
}

::-webkit-scrollbar {
    width: 4px;
}

::-webkit-scrollbar-track {
    background: #f7f0f0;
}

::-webkit-scrollbar-thumb {
    background-color: #5d58f2;
    border-radius: 10px;
    transition: all 0.5s ease;
}

::-webkit-scrollbar-thumb:hover {
    background: #1712a9;
}

.emoji-picker {
    position: absolute;
    bottom: 60px;
    right: 10px;
    z-index: 1000;
}

.emoji-picker aside {
    height: 450px !important;
    width: 260px !important;
}

.search-container {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
}

.search-container input {
    position: relative;
}

.search-input {
    width: 100%;
    padding: 8px 40px 8px 12px;
    border: 1px solid #ccc;
    border-radius: 30px;
}

.search-container button {
    position: absolute;
    right: 0px;
    cursor: pointer;
}

.contact-topbar {
    position: sticky;
    top: 0;
    background-color: #f8f8f8;
}

.back-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
}

@media (max-width: 1028px) {
    .contact-list {
        width: 37%;
    }
}

@media (max-width: 790px) {
    .contact-list {
        width: 46%;
    }
}

@media (max-width: 577px) {
    .contact-list {
        width: 100%;
    }

    .chat-section {
        display: none;
    }

    .chat-section-2 {
        display: block;
        width: 100%;
        position: absolute;
        top: 80px;
        background-color: white;
    }
}