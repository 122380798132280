.job-search-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.job-search-top label {
    font-size: 15px;
}

.job-by-search {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
}

.search-wrapper {
    display: flex;
    align-items: center;
    position: relative;
}

#job-search {
    padding: 8px 40px 8px 12px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 12px;
    width: 100%;
    outline: none;
}

.search-btn {
    position: absolute;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}
#job-location{
    font-size: 13px;
    outline: none;
}