.achieve-img img {
    width: 100%;
}

.achieve-img {
    width: 290px;
    margin: auto;
}

.achieve-text p {
    font-size: 15px;
    margin-top: 20px;
}

.process-card {
    padding: 15px 23px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    transition: transform 0.5s ease;
}
.process-card:hover {
    transform: scale(1.05);
}

.card-icon {
    height: 69px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-icon svg {
    font-size: 35px;
}
.card-heading{
    text-align: center;
    margin-top: 10px;
}
.card-heading h5{
    color: #4440da;
}
.card-text{
    margin-top: 25px;
    margin-bottom: 25px;
}
.card-text p{
    margin-bottom: 15px;
}