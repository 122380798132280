.role-title {
    font-size: 32px;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 0;
}
.role-title span{
    color: #4440da;
}
.role-card {
    width: 100%;
    border: 1px solid rgb(218, 213, 213);
    padding: 10px 20px;
    border-radius: 7px;
    cursor: pointer;
}

.role-checkbox {
    display: flex;
    justify-content: end;
    align-items: center;
}

.role-checkbox input {
    width: 20px;
    height: 20px;
}

.role-image {
    width: 250px;
    height: 250px;
    margin: auto;
}

.role-image img {
    width: 100%;
}

.role-btn {
    display: flex;
    justify-content: end;
    align-items: center;
}

.role-btn button {
    outline: none;
    border: 1px solid #17B8FC;
    padding: 7px 20px;
    background: transparent;
    border-radius: 7px;
    font-size: 15px;
    cursor: pointer;
    color: #17B8FC;
    transition: all 0.6s ease;
}

.role-btn button:hover {
    background-color: #17B8FC;
    color: #fff
}