.hero-bg {
    background-image: url('../images/hero-bg.png');
    background-size: contain;
    height: 80vh;
    background-position: center;
    background-repeat: no-repeat;
}

.hero-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
}

.technology-img {
    height: 212px;
}

.technology-img img {
    width: 100%;
    height: 100%;
}

.technology-name h2 {
    color: black;
}

.list-unstyled {
    color: black;
    font-weight: 700;
}

.technology {
    border: 2px solid #4440da;
    padding: 10px;
    border-radius: 10px;
}

.hero-text {
    text-align: center;
}

.plateform-text span,
.review-text span,
.hero-text span {
    color: #4440da;
}

.hero-text h1 {
    font-size: 50px;
}

.hero-text p {
    margin-top: 10px;
    font-size: 20px;
}

.plateform-bg {
    background-color: #f5f7fb;
    padding: 50px 0px;
    margin-top: 50px;
}

.plateform-text h1 {
    margin: 0;
    font-size: 28px;
}

.plateform-cards {
    background-color: white;
    border-radius: 6px;
    width: 100%;
    padding: 15px 10px;
    transition: all 0.3s ease;
}

.plateform-cards:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 4px 15px;
}

.card-text h5 {
    font-size: 18px;
    margin: 0;
}

.card-text p {
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 13px;
}

.review-bg {
    background: white;
    padding: 50px 0px;
}

.review-text h1 {
    font-size: 28px;
    margin: 0px;
}

.slider-card-img {
    width: 70px;
    text-align: center;
    margin: auto;
}

.slider-card-img img {
    width: 100%;
    border-radius: 50%;
    margin-top: 40px;
}

.slider-card-text {
    background-color: white;
    width: 700px;
    margin: auto;
    padding: 20px 40px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 4px 15px;
    border-radius: 8px;
}

.slider-card-text p {
    margin: 0px;
    color: grey;
}

.custom-carousel .carousel-indicators {
    display: none;
}

.custom-carousel .carousel-control-prev,
.custom-carousel .carousel-control-next {
    display: block;
}

.custom-carousel .carousel-control-prev-icon,
.custom-carousel .carousel-control-next-icon {
    background-color: #4440da;
    border-radius: 50%;
}

.custom-carousel .carousel-control-prev,
.custom-carousel .carousel-control-next {
    display: flex;
    align-items: center;
}

.resume-bg {
    background-color: #4440da;
    border-radius: 25px;
}

.resume-img {
    text-align: center;
}

.resume-img img {
    height: auto;
    max-width: 100%;
    border: none;
    border-radius: 0;
    box-shadow: none;
    width: 50%;
}

.resume-text {
    height: 240px;
}

.resume-text h1 {
    margin-top: 35px;
    margin-bottom: 0px;
}

.resume-text p {
    margin-top: 30px;
    margin-bottom: 0px;
    color: #e9d7d7;
}

.job-card {
    width: 100%;
    padding: 10px 25px;
    background: white;
    border-radius: 9px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 4px 15px;
    transition: transform 0.3s ease;
}

.job-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.job-icon {
    width: 55px;
}

.job-icon img {
    width: 100%;
}

.job-head {
    display: flex;
    gap: 15px;
    align-items: center;
}

.job-title h4 {
    font-size: 18px;
    margin: 0;
}

.job-location {
    margin-top: 16px;
}

.job-location span {
    color: #4440da;
    margin-left: 25px;
}

.job-post-date {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    align-items: center;
}

.job-post-date p {
    font-size: 10px;
    margin: 0;
}

.job-post-date .job-type {
    border-radius: 7px;
    color: white;
    font-weight: 600;
    cursor: pointer;
    background-color: #4440da;
    text-decoration: none;
    font-size: 11px;
}

.more-jobs-btn {
    text-align: center;
}

.more-jobs-btn button {
    background: white;
    color: #4440da;
    border: 1px solid #4440da;
    padding: 10px 20px;
    outline: none;
    font-weight: 600;
    border-radius: 30px;
    transition: all 0.7s ease;
}

.more-jobs-btn button:hover {
    background: #4440da;
    color: white;
}

.company-img {
    width: 150px;
    margin: auto;
}

.company-text h1 span {
    color: #4440da;
}

.company-text h1 {
    font-size: 25px;
}

.company-img img {
    width: 100%;
}

.callout-bg {
    background-image: url('../images/hero.jpg');
    background-size: cover;
    height: 60vh;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    background-attachment: fixed;
}

.callout-bg::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 1;
}

.callout-text {
    width: 480px;
    position: relative;
    width: 480px;
    color: #fff;
    z-index: 2;
}

.common {
    color: #4440da;
    font-weight: 600;
}

.stats-card {
    padding: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    background-image: url('../images/stats.jpg');
    background-position: center;
    background-size: cover;
    border-radius: 5px;
}

.stats-card h1 {
    font-size: 30px;
    margin-top: 17px;
}

.stats-text h1 {
    font-size: 30px;
}

.stats-p {
    font-size: 14px;
}

.process-card {
    padding: 15px 23px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    transition: transform 0.5s ease;
}

.process-card:hover {
    transform: scale(1.05);
}

.card-icon {
    height: 69px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-icon svg {
    font-size: 35px;
}

.card-heading {
    text-align: center;
    margin-top: 10px;
}

.card-heading h5 {
    color: #4440da;
}

.card-text {
    margin-top: 25px;
    margin-bottom: 25px;
}

.card-text p {
    margin-bottom: 15px;
}

@media (max-width: 1009px) {
    .slider-card-text {
        width: 441px;
    }
}

@media (max-width: 578px) {
    .slider-card-text {
        width: 285px;
    }
}

@media (max-width: 995px) {
    .resume-text h1 {
        font-size: 23px;
    }

    .resume-text p {
        font-size: 15px;
    }
}