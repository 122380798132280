.card {
    margin: 1rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-content {
    padding: 2rem;
}

.box-center {
    text-align: center;
    margin-bottom: 2rem;
}

.typography-title {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
}

.typography-subtitle {
    font-size: 1.2rem;
    color: #555;
}

.grid-item {
    padding: 0.5rem;
}

.divider {
    margin: 1.5rem 0;
}

.typography-body {
    font-size: 1rem;
    color: #333;
}

.typography-bold {
    font-weight: 600;
}

.typography-description {
    font-size: 1rem;
    color: #666;
}
