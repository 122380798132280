.header-bg {
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 100;
    height: 80px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.header-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
}

.logo {
    display: flex;
    align-items: center;
}

.logo-img {
    width: 90px;
}

.logo img {
    width: 122%;
}

.logo p {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
}

.logo p span {
    color: #4440da;
}

.logo a {
    text-decoration: none;
    color: black;
}

nav ul {
    padding: 0px;
    margin: 0px;
    list-style: none;
    display: flex;
    align-items: center;
}

nav ul li {
    display: inline-flex;
    margin-right: 20px;
    position: relative;
}

nav ul li a {
    text-decoration: none;
    color: black;
    font-size: 14px;
    transition: all 0.3s ease;
    position: relative;
}

nav ul li a:hover {
    color: #4440da;
}

nav ul li a.active {
    color: #4440da;
}

nav ul li a::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background-color: #4440da;
    position: absolute;
    bottom: -4px;
    left: 0;
    transition: width 0.3s ease, left 0.3s ease;
}

nav ul li a:hover::after,
nav ul li a.active::after {
    width: 100%;
}

.menu-icon {
    display: none;
    cursor: pointer;
    color: #4440da;
}

.menu-icon svg {
    font-size: 35px;
}

.close-icon {
    display: flex;
    justify-content: end;
    color: #4440da;
    cursor: pointer;
}

.mobile-navbar a:hover {
    color: #4440da;
    transition: all 0.3s ease;
}

.mobile-navbar a {
    color: black;
    text-decoration: none;
}

.mobile-navbar {
    display: inline-grid;
    row-gap: 18px;
    width: 100%;
    text-align: center;
}

.mobile-main.slide-in {
    animation-name: slideIn;
    position: fixed;
}

.mobile-main.slide-out {
    animation-name: slideOut;
}

.mobile-main {
    position: absolute;
    top: 0;
    right: 0;
    background-color: white;
    background-image: url('../images/particels.png');
    z-index: 100;
    width: 320px;
    padding: 10px 15px;
    height: 100vh;
    border-radius: 5px;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
}

.employer-btn {
    padding: 7px 29px;
    background: #4440da;
    border-radius: 30px;
    color: white;
}

.employer-btn a {
    color: white;
    font-size: 20px;
}

.employer-btn a:hover {
    color: white;
}

.employer-btn a.active {
    color: white;
}

.profile {
    width: 35px;
    border-radius: 50%;
    position: relative;
    margin-right: 5px;
}

.profile img,
.model-img img {
    width: 100%;
    border-radius: 50%;
}

.profile-icon {
    cursor: pointer;
}

.model-bg {
    width: 150px;
    padding: 15px 10px;
    position: fixed;
    top: 83px;
    right: 5px;
    background-color: white;
    z-index: 200;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 7px;
}

.model ul {
    margin: 0;
    padding: 0;
}

.model ul li {
    margin-bottom: 10px;
}

.model ul li a {
    text-decoration: none;
    color: black;
    font-size: 13px;
    transition: all 0.5s ease;
}

.model ul li a:hover {
    color: #4440da;
}

.model .model-svg {
    font-size: 18px;
    margin-right: 7px;
}

.model-img {
    width: 35px;
    margin: auto;
}

.model-title p {
    font-size: 12px;
}

.navbar-account {
    display: none;
    cursor: pointer;
}

.navbar-left-icons {
    display: none;
    align-items: center;
}

.chat,
.notification {
    margin-right: 20px;
}

@media (max-width: 1009px) {
    .menu-icon {
        display: block;
    }

    nav {
        display: none;
    }

    .navbar-account {
        display: block;
    }

    .navbar-left-icons {
        display: flex;
        gap: 3px;
    }

    .navbar-left-icons svg {
        font-size: 23px;
    }
}

@media (min-width: 1009px) {
    .mobile-main {
        display: none;
    }
}

@media (max-width: 582px) {
    .logo-img {
        width: 67px;
    }

    .logo p {
        font-size: 15px;
    }

    .profile {
        width: 25px;
    }

    .chat,
    .notification {
        margin-right: 4px;
    }
}

@keyframes slideIn {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes slideOut {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(100%);
    }
}