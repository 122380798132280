.main-post-section {
    padding: 10px;
    overflow: hidden;
    margin-top: 10px;
    height: 70vh;
}

.top-bar {
    display: flex;
    justify-content: space-between;
}

.filter-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 30%;
    border: 1px solid rgba(0, 0, 255, 0.74);
    border-radius: 5px;
    padding: 0px 10px;

}

.filter-logo svg {
    font-size: 22px;
}

.filter-input {
    width: 100%;
}

.table-p p {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 52px;
}

.filter-input input {
    width: 100%;
    border: none;
    outline: none;
    font-size: 12px;
    font-family: poppins;
    font-weight: 400;
    color: gray;
}

.arrow-icon svg {
    font-size: 17px;
}

.post-btn button {
    border: none;
    outline: none;
    background: rgba(0, 0, 255, 0.74);
    padding: 10px;
    border-radius: 5px;
    color: #ffff;
    font-size: 13px;
    font-family: poppins;
    font-weight: 600;
}

.status-wrapper {
    display: flex;
    align-items: baseline;
    gap: 4rem;
}

.checkbox input {
    font-size: 17px;
}

.job-title {
    font-size: 17px;
    font-family: poppins;
    font-weight: 500;

}

.sponser-sec {
    font-size: 17px;
    font-family: poppins;
    font-weight: 500;
}

.sponser-sec p {
    font-size: 17px;
    font-family: poppins;
    font-weight: 500;
    margin: 0px;
}

.dropdown-sec {
    display: flex;
    align-items: baseline;
}

.dropdown-sec p {
    font-size: 17px;
    font-family: poppins;
    font-weight: 500;
    margin: 0px;
}




.dropdown-sec select {
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 17px;
    font-family: poppins;
    font-weight: 500;
}

.location-sec span {
    font-size: 14px;
    font-weight: 600;
    color: rgba(0, 0, 255, 0.74);
}

.location-sec p {
    font-size: 11px;
    font-weight: 400;
    color: gray;
}

.draft-sec p {
    font-size: 17px;
    font-family: poppins;
    font-weight: 500;
}

.draft-sec svg {
    font-size: 23px;
}

.check-input {
    cursor: pointer;
    width: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    height: 49px;
}

.post-btn {
    text-align: center;
}

.model-bg-2 {
    width: 133px;
    padding: 0px 15px 10px;
    position: fixed;
    top: 227px;
    right: 77px;
    background-color: white;
    z-index: 200;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 7px;
}

@media screen and (max-width: 930px) {
    .filter-section {
        width: 45%;
    }
}
@media (max-width: 1274px) {
    .model-bg-2 {
        position: absolute;
        top: 281px;
        right: 132px;
    }
}
@media (max-width: 770px) {
    .model-bg-2 {
        position: absolute;
        top: 317px;
        right: 64px;
    }
}
@media (max-width: 568px) {
    .model-bg-2 {
        position: absolute;
        top: 321px;
        right: 17px;
    }
}
@media (max-width: 375px) {
    .model-bg-2 {
        position: absolute;
        top: 352px;
        right: 10px;
    }
}

@media screen and (max-width: 768px) {
    .top-bar {
        display: block;
    }

    .filter-section {
        width: 100%;
        margin: auto;
    }

    .dropdown-sec {
        display: block;
    }

    .status-wrapper {
        display: block;
    }

}