.profile-header {
    position: relative;
    background: #f5f7fb;
    color: #4440da;
    padding: 30px 0;
}

.profile-header img {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    object-fit: cover;
    border: 5px solid #fff;
}

.profile-para {
    margin-top: 20px;
}

.profile-info {
    margin-top: 50px;
}

.profile-info h2 {
    font-weight: 600;
}

.profile-card {
    margin-top: 30px;
}
.list-unstyled li {
    margin-bottom: 10px;
}