.not-found {
    width: 300px;
}

.not-found img {
    width: 100%;
}

.flex {
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center
}